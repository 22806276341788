import $ from 'jquery';
//import jQuery from 'jquery';
//import 'bootstrap/js/dist/util';
//import 'bootstrap/js/dist/dropdown';
import { Tooltip, Toast, Popover } from 'bootstrap';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	
	// Navigation levels
	$('.navbar .dropdown').hover(function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(1);
	}, function() {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(1);
	});

	$('.navbar .dropdown > a').click(function(){
		location.href = this.href;
	});

	//Home slider - matrix
	jQuery('.matrix-carousel').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 6000,
		responsive: [
			{
				breakpoint: 2560,
				settings: {
				  slidesToShow: 5
				}
			},
			{
				breakpoint: 1920,
				settings: {
				  slidesToShow: 4
				}
			},			
			{
			  breakpoint: 1320,
			  settings: {
				slidesToShow: 3
			  }
			},
			{
			  breakpoint: 1024,
			  settings: {
				slidesToShow: 2
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1
			  }
			}
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		  ]	
	});	

	// Matrix lege finaliteiten verwijderen
	$(".finaliteiten-wrapper").each(function() {
		if ($(this).children(".bg--white").children().length == 0){
			$(this).remove();
		}
	});

});